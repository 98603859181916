<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="columns is-multiline is-mobile is-vcentered" style="width: 100%">
          <div class="column is-10">
            <p class="modal-card-title">
              AGREGAR TABLA DE. INFORMACIÓN PARA ORDENAR
            </p>
          </div>
          <div class="column is-2 has-text-right">
            <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
          </div>
        </div>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="field control">
              <label class="label">Nombre de la tabla</label>
              <input
                class="input"
                type="text"
                placeholder="Nombre de la tabla"
                v-model="tableNameLocal"
                name="tableNameLocal"
                v-validate="'required'"
                data-vv-as="del nombre de la tabla"
              >
              <span v-show="vverrors.has('tableNameLocal')" class="help is-danger">{{ vverrors.first('tableNameLocal') }}</span>
            </div>
          </div>
          <div class="column is-12">
            <p class="paragraph mb-3">Agregar columnas nuevas a la tabla</p>
            <div class="columns is-multiline">
              <div class="column is-6">
                <div class="field is-flex is-flex-direction-row is-align-items-center">
                  <span class="material-icons text_green is-size-5 mr-2">
                    check
                  </span>
                  <p>Nombre del producto</p>
                </div>
                <div class="field is-flex is-flex-direction-row is-align-items-center">
                  <span class="material-icons text_green is-size-5 mr-2">
                    check
                  </span>
                  <p>Descripción</p>
                </div>
                <div class="field is-flex is-flex-direction-row is-align-items-center">
                  <span class="material-icons text_green is-size-5 mr-2">
                    check
                  </span>
                  <p>Catálogo</p>
                </div>
                <div class="field is-flex is-flex-direction-row is-align-items-center">
                  <span class="material-icons text_green is-size-5 mr-2">
                    check
                  </span>
                  <p>Disponibilidad</p>
                </div>
                <div class="field is-flex is-flex-direction-row is-align-items-center">
                  <span class="material-icons text_green is-size-5 mr-2">
                    check
                  </span>
                  <p>Clave MI</p>
                </div>
              </div>
              <div class="column is-6">
                <div class="field" v-for="(columnTable, index) in optionalColumnsLocal" :key="index">
                  <template v-if="columnTable.name !== 'MONEDA'">
                    <label class="checkbox">
                      <input type="checkbox" v-model="optionalColumnsLocal[index].active">
                      {{ lodash.capitalize(columnTable.name) }}
                    </label>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="field control">
              <label class="label">Información complementaria</label>
              <textarea
                class="textarea"
                v-model="additionalInformationLocal"
                placeholder="Información complementaria"
              />
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          :disabled="loading"
          @click="$emit('close-modal')"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          @click="actionModal()"
          :class="{ 'is-loading' : loading }"
          :disabled="loading"
        >
          AGREGAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import productVariablesTable from '@/constant/productVariablesTable'

export default {
  name: 'ModalHeaderInformatioToOrder',
  data () {
    return {
      tableNameLocal: null,
      additionalInformationLocal: null,
      productVariablesTable: productVariablesTable,
      // Contantes definidas que deben ser igual en las contantes del API
      optionalColumnsLocal: [
        {
          id: productVariablesTable.COST_HEADER_TABLE,
          name: 'COSTO',
          active: false
        },
        {
          id: productVariablesTable.QUANTITY_HEADER_TABLE,
          name: 'CANTIDAD',
          active: false
        },
        {
          id: productVariablesTable.DIAMETER_HEADER_TABLE,
          name: 'DIAMETRO',
          active: false
        },
        {
          id: productVariablesTable.CURRENCY_HEADER_TABLE,
          name: 'MONEDA',
          active: false
        },
        {
          id: productVariablesTable.DISCOUNT_HEADER_TABLE,
          name: 'DESCUENTO',
          active: false
        }
      ],
      loading: false,
      lodash: _
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    idTable: {
      type: Number,
      default: null
    },
    dataTableParent: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    async actionModal () {
      if (await this.$validator.validateAll()) {
        const { active } = this.optionalColumnsLocal.find(e => e.name === 'COSTO')
        const indexCurrency = this.optionalColumnsLocal.map(item => item.id).indexOf(productVariablesTable.CURRENCY_HEADER_TABLE)
        this.optionalColumnsLocal[indexCurrency].active = active

        const dataTable = {
          idTable: this.idTable,
          tableName: this.tableNameLocal,
          optionalColumns: this.optionalColumnsLocal,
          additionalInformation: this.additionalInformationLocal
        }
        this.$emit('set-data-add-header-table', dataTable)
      }
    }
  },
  beforeMount () {
    if (this.idTable) {
      this.tableNameLocal = this.dataTableParent.tableName
      this.additionalInformationLocal = this.dataTableParent.additionalInformation
      this.optionalColumnsLocal = this.dataTableParent.header
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
